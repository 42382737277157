
.main-wrapper {
   height: 80%;
   padding: 0 30px 0 10px;    
   .title{
       text-align: center;
       padding-bottom: 20px;
       display: flex;
       .titleData{
           line-height: 40px;
           font-size: 14px;
           font-weight: bold;
           flex: 1;
           line-height: 20px;
       }
   }   
   .el-scrollbar {
       height: 100%;
       ::v-deep .el-scrollbar__wrap {
           height: 100%;
           overflow-x: hidden;    
            .el-scrollbar__view {
               height: 100%;
           }
       }
   }
    .footer-button{
       text-align: center;
       padding-top: 15px;
   } 
}
